export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavDropdown',
        name: 'จัดการหวย',
        route: '/manage-lotto',
        icon: 'cil-list',
        items: [
          {
            name: 'รายการหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/market'
          },
          {
            name: 'จัดกลุ่มหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/group'
          },
          {
            name: 'อัตราจ่าย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-lotto/pay-rate'
          },
          // {
          //   name: 'หวยชุด',
          //   fontIcon: 'fas fa-angle-double-right',
          //   to: '/manage-lotto/lotto-set'
          // }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'จัดการสมาชิก',
        route: '/manage-account',
        fontIcon: 'fas fa-user-plus',
        items: [
          {
            name: 'ระดับเอเย่นต์',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-account/level'
          },
          {
            name: 'บัญชีบริษัท',
            fontIcon: 'fas fa-angle-double-right',
            to: '/manage-account/company'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'ตั้งค่าสมาชิก',
        route: '/user-setting',
        fontIcon: 'fas fa-user-cog',
        items: [
          {
            name: 'เปิด/ปิด กลุ่มหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/user-setting/lotto-group'
          },
          {
            name: 'เปิด/ปิด รายการหวย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/user-setting/lotto-market'
          },
          {
            name: 'เปิด/ปิด/ตั้งค่า อัตราจ่าย',
            fontIcon: 'fas fa-angle-double-right',
            to: '/user-setting/payrates'
          },
          {
            name: 'ตั้งค่าหวยชุด',
            fontIcon: 'fas fa-angle-double-right',
            to: '/user-setting/lottoset'
          }
        ]
      }
    ]
  }
]
